import React, { useState, useEffect, useMemo } from "react";
import { graphql } from "gatsby";
import { useLazyQuery } from "@apollo/react-hooks";
import { get } from "lodash";
import PropTypes from "prop-types";
import { Button } from "antd";

import { ContentBlock, TextBlock } from "../../components/Blocks";
import Pages from "../../components/Pages";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import { makeUrl, removeEmptyKeys, removePreloader } from "../../helpers";
import { SlideNews } from "../../components/Carousel/Slide";
import { Share } from "../../widgets";
import { CONTENT_SHRINES } from "../../queries/queries.graphql";
import seoData from "../../components/SEO/data";
import { showMore } from "../../constants";
import { useThemeContext } from "../../components/Layout/ThemeContext";

export const query = graphql`
	query contentShrinesQuery($slug: String!, $pagePath: String!)  {
		hasura {
			...ContentShrines
			...PageSettings
		}
	}
`;

export default function ContentShrines({ data, pageContext }) {
	const { theme } = useThemeContext();
	const { slug } = pageContext;

	const url = makeUrl.contentShrines(pageContext);
	const limit = 9;

	const breadcrumbContext = {
		item: {
			title_full: get(pageContext, "city.title_full", ""),
			slug: get(pageContext, "city.slug", ""),
		},
		item2: {
			title_full: get(pageContext, "title_full", ""),
			slug: get(pageContext, "slug", ""),
		},
	};

	function makeData(data = []) {
		return data.map((shrine, idx) => {
			const { title_short, title_full, main_image_preview, main_image, main_image_mobile } = shrine;

			const path = makeUrl.shrine(shrine);
			const image = get(main_image_preview, "src", get(main_image, "src", ""));
			const title = title_short || title_full;

			return (
				<div key={idx} className={"col-xs-12 col-md-4 mb-4"}>
					<SlideNews
						path={path}
						src={image}
						title={title}
						mediaSize={["100%", 400]}
						responsive={[
							{
								src: get(main_image_mobile, "src", ""),
								media: "(max-width: 930px)",
							},
						]}/>
				</div>
			);
		});
	}

	const [contentShrines, setContentShrines] = useState(makeData(get(data, "hasura.shrines", [])));
	const [offset, setOffset] = useState(limit);
	const seo = get(data, "hasura.page_settings[0]", {});

	const [loadShrines, { called, loading, error, data: contentShrinesData }] = useLazyQuery(
		CONTENT_SHRINES,
		{
			variables: {
				slug,
			},
			partialRefetch: true,
		});

	useEffect(() => {
		if (called && !error && !loading) {
			setContentShrines([...contentShrines, ...makeData(get(contentShrinesData, "shrines", []))]);
			setOffset(get(contentShrinesData, "shrines", []).length < limit ? null : offset + limit);
		} else if (error) {
			setOffset(null);
		}

		removePreloader(!loading || error);
	}, [loading]);

	const newsBlock = useMemo(() => contentShrines, [contentShrines]);

	return (
		<Pages url={url} entity={seo || get(seoData, "all_shrines", {})}>
			<ContentBlock key={"breadcrumbs"}>
				<div className="container">
					<Breadcrumbs currentLabel={"Свтяыни"} pageContext={breadcrumbContext} />
				</div>
			</ContentBlock>
			<ContentBlock key={"main-content"}>
				<div className={"container"}>
					<TextBlock title={"Все Святыни"}>
						<div className={"row"}>
							{newsBlock}
						</div>
					</TextBlock>
					<div className="row justify-content-center mb-5">
						<Button
							type={"primary"}
							disabled={loading || !offset}
							loading={loading}
							onClick={() => loadShrines({
								variables: {
									offset,
								},
							})}
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.text,
								lineHeight: theme.lineHeight.text,
								color: theme.color.body,
								backgroundColor: theme.color.text,
							})}
						>
							{showMore}
						</Button>
					</div>
				</div>
			</ContentBlock>
			<div className={"container"}>
				<Share url={url} pageTitleShort={"Все святыни"} />
			</div>
		</Pages>
	);
}

ContentShrines.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
};

ContentShrines.defaultProps = {
	data: {},
	pageContext: {
		slug: "",
	},
};
